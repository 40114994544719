@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Responsive Full Background Image Using CSS
*/
.main-body {
    /* Location of the image */
    background-image: url(/static/media/image4.664c6436.jpg);
    
    /* Image is centered vertically and horizontally at all times */
    /* background-position: center center; */
    
    /* Image doesn't repeat */
    background-repeat: no-repeat;
    
    /* Makes the image fixed in the viewport so that it doesn't move when 
       the content height is greater than the image height */
    background-attachment: fixed;
    
    /* This is what makes the background image rescale based on its container's size */
    background-size: cover;
    
    /* Pick a solid background color that will be displayed while the background image is loading */
    background-color:#ffff;
    
    /* SHORTHAND CSS NOTATION
     * background: url(background-photo.jpg) center center cover no-repeat fixed;
     */
  }
  
  /* For mobile devices */
  @media only screen and (max-width: 768px) {
    .main-body {
      /* The file size of this background image is 93% smaller
       * to improve page load speed on mobile internet connections */
       background-image: url(/static/media/image4.664c6436.jpg);
      /* background-image: url(../assets/background_image_mobile.jpg); */
      background-size: auto;
    }
      }
/* Set height to 100% for body and html to enable the background image to cover the whole page: */
body, html {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Open Sans', Helvetica, sans-serif;
}

.bgimg {
  /* Background image */
  /* background-image: url('../../assets/images/image4.jpg'); */
  /* Full-screen */
  min-height: 100vh;
  height: 100%;
  /* Center the background image */
  background-position: center;
  /* Scale and zoom in the image */
  /* background-size: cover; */
  /* Add position: relative to enable absolutely positioned elements inside the image (place text) */
  /* position: relative; */
  /* Add a white text color to all elements inside the .bgimg container */
  color: white;
  /* Add a font */
  /* Set the font-size to 25 pixels */
  font-size: 25px;
  width: 100%;
}

/* Position text in the top-left corner */
.topleft {
  position: absolute;
  top: 0;
  left: 16px;
}

/* Position text in the bottom-left corner */
.bottomleft {
  position: absolute;
  bottom: 0;
  left: 16px;
}

/* Position text in the middle */
.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Style the <hr> element */
hr {
  margin: auto;
  width: 50%;
}
.title{
    font-size: 3.6rem !important;
}

.bg-layout {
  width: 100%;
  background-color: rgba(0,0,0, 0.7);
  display: block;
  min-height: 100vh;
  height: 100%;
}

.h1 {
  color: #DDD;
  font-size: 48px;
  margin: 20px 0;
  font-weight: bold;
}

.brand-logo {
  width: 160px;
  border-radius: 10px;
}

.social_icon_wrapper {
  margin-top: 1%;
}

.social_icon {
  padding-right: 15px;
  font-size: 18px;
}

.social_icon:hover {
  cursor: pointer;
}

.fa-facebook-f:hover {
  color: #3b5998;
}

.fa-twitter:hover {
  color:#1DA1F2;
}

.fa-linkedin-in:hover {
  color:#0077B5
}

.fa-instagram:hover {
  color: #e4405f;
}

@media screen and (min-width: 400px) {
  .brand-logo {
    width: 200px;
  }
}
.services {
  margin-top: 20px;
}
.services span {
  text-transform: uppercase;
}
.c-wrap {
  display: flex;
  justify-content: center;
  position: absolute;
  background: #dadada;
  bottom: 0;
  width: 100%;
  height: auto;
  top: 220px;
}
.content {
  width: 100%;
}
.content input, .emails {
  height: 36px;
  border-radius: 40px;
  outline: none;
  border: 0;
  background: #efefef;
  text-align: center;
  width: 100%;
  max-width: 400px;
  display: block;
  margin: 0 auto;
  font-size: 14px;
}
.button, .buttons {
  cursor: pointer;
  background-color: #333; /* Green */
  border: none;
  color: white;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  display: inline;
  font-size: 16px;
  margin: 10px 0;
  border-radius: 40px;
  outline: none;
  border: 0;
  width: 170px;
  border: 1px solid transparent;
}
.button:active, .button:focus {
  border: 1px solid #fff;
}
.button-social {
  background: transparent;
  border: none;
  padding: 0 8px;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  position: relative;
  top: 0;
}
.button-social:hover {
  position: relative;
  top: -2px;
  opacity: 0.8;
}
.content p {
  font-size: 14px;
}
.button:hover {
  opacity: 0.9;
}

/* styles for Countdown timer */
.Countdown{
  margin: 10px auto;
  padding-bottom: 20px;
}

.Countdown-col{
  display: inline-block;
}

.Countdown-col-element{
  display: inline-block;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong{
  font-size: 50px;
}

.form-error{
    color: red;
}
.form-submit-msg{
    font-weight: bold;
}
.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  h3 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }
  
  h3 span {
    background: #fff;
    padding: 0 15px;
  }
  .title-404 {
    font-size: 12rem;
  }
  .sub-title-404 {
    font-size: x-large;
    margin: 0px;
  }
  .info-icon {
      margin-right: 5px;
    font-size: 2rem !important;
  }
  .home-icon{
    margin-top: 20px;
  }
  
  .home-icon-link{
      color: #fff !important;
  }
