@import "../../assets/css/responsive-full-background-image.css";
.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  h3 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }
  
  h3 span {
    background: #fff;
    padding: 0 15px;
  }
  .title-404 {
    font-size: 12rem;
  }
  .sub-title-404 {
    font-size: x-large;
    margin: 0px;
  }
  .info-icon {
      margin-right: 5px;
    font-size: 2rem !important;
  }
  .home-icon{
    margin-top: 20px;
  }
  
  .home-icon-link{
      color: #fff !important;
  }