/* Responsive Full Background Image Using CSS
*/
.main-body {
    /* Location of the image */
    background-image: url(../images/image4.jpg);
    
    /* Image is centered vertically and horizontally at all times */
    /* background-position: center center; */
    
    /* Image doesn't repeat */
    background-repeat: no-repeat;
    
    /* Makes the image fixed in the viewport so that it doesn't move when 
       the content height is greater than the image height */
    background-attachment: fixed;
    
    /* This is what makes the background image rescale based on its container's size */
    background-size: cover;
    
    /* Pick a solid background color that will be displayed while the background image is loading */
    background-color:#ffff;
    
    /* SHORTHAND CSS NOTATION
     * background: url(background-photo.jpg) center center cover no-repeat fixed;
     */
  }
  
  /* For mobile devices */
  @media only screen and (max-width: 768px) {
    .main-body {
      /* The file size of this background image is 93% smaller
       * to improve page load speed on mobile internet connections */
       background-image: url(../images/image4.jpg);
      /* background-image: url(../assets/background_image_mobile.jpg); */
      background-size: auto;
    }
      }